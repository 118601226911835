import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Dispatch, SetStateAction, useState } from 'react';
import { maskPhone } from '../../../utils';
import { patchAuthUser, postAuthSignUp } from '../../../services/Api';

export function ModalUser(props: { translateObj: any, show: any, setShow: Dispatch<SetStateAction<boolean>>, setModified: Dispatch<SetStateAction<number>>, companyId: any, dataUser: any }) {
  const { translateObj, show, setShow, setModified, companyId, dataUser } = props;
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false);
  }

  const initialValues = {
    "id": dataUser.id ? dataUser.id : "",
    "name": dataUser.name ? dataUser.name : "",
    "email": dataUser.email ? dataUser.email : "",
    "phone": dataUser.phone ? dataUser.phone : "",
    "is_admin": dataUser.is_admin ? dataUser.is_admin : false,
    "password": "",
    "company_id": companyId,
  }

  const currentSchema = Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "email": Yup.string()
      .email(translateObj("VALID.EMAIL"))
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(100, translateObj("VALID.MAX.CARACTER", { "length": 100 }))
      .required(translateObj("VALID.REQUIRED")),
    "phone": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "password": dataUser?.id 
      ? Yup.string()
        .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
        .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      : Yup.string()
        .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
        .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
        .required(translateObj("VALID.REQUIRED")),
    "is_admin": Yup.boolean()
      .required(translateObj("VALID.REQUIRED")),
  })

  const submitForm = (values: any) => {
    setLoading(true)

    let data = values

    data.alias_name = values.name
    data.phone = values.phone.replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")

    if(dataUser?.id) {
      patchAuthUser(data).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    } else {
      postAuthSignUp(data).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    }
  }

  const removeUser = (user: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(translateObj("MESSAGE.WANTREMOVEUSER"))) {
      setLoading(true)

      patchAuthUser({...user, is_active: false}).then(() => {
        setModified(Math.random())
        handleClose()
        setLoading(false)
      })
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitForm}>
        {({ setFieldValue }) => (
          <Form id='kt_user_form'>
            <Modal.Body>
              <div>
                <h2 className='fw-bolder text-dark'>{translateObj("MESSAGE.USER.DATA")}</h2>

                <div className='text-gray-400 fw-bold fs-6 mb-15'>
                  {translateObj("MESSAGE.USER.NEWACCOUNT")}
                </div>

                <Field name='id' className='form-control form-control-lg form-control-solid' style={{ display: "none" }} />

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.NAME")}</label>

                  <Field name='name' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='name' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.PHONE")}</label>

                  <Field name='phone' className='form-control form-control-lg form-control-solid' maxLength={15} onKeyUp={(e: any) => setFieldValue('phone', maskPhone(e.target.value))} />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='phone' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.EMAIL")}</label>

                  <Field type="email" name='email' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='email' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                    <label className={`fs-6 fw-bold form-label ${!dataUser?.id ? 'required' : ''}`}>{translateObj("MESSAGE.PASSWORD")}</label>

                  <Field type="password" name='password' className='form-control form-control-lg form-control-solid' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='password' />
                  </div>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-bold form-label required'>{translateObj("MESSAGE.ADMIN")}</label>

                  <Field type="checkbox" name='is_admin' className='ms-2 form-check-input' />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='is_admin' />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <>
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {translateObj("MESSAGE.LOADING") + ''}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </>
              ) : (
                <>
                  {dataUser?.id && (
                    <button type='button' className='btn btn-danger text-white position-absolute' style={{ left: '4.5%' }} onClick={() => removeUser(dataUser)}>
                      <i className='fa fa-times'></i>
                      <span className='mx-1'>{translateObj("MESSAGE.DELETEUSER")}</span>
                    </button>
                  )}
                  <button type='button' className='btn btn-default' onClick={handleClose}>
                    {translateObj("BTN.CANCEL")}
                  </button>
                  <button type='submit' className='btn btn-dark text-white'>
                    <i className='fa fa-check'></i>
                    <span className='mx-1'>{translateObj("BTN.SAVE")}</span>
                  </button>
                </>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}